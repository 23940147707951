<template>
  <div class="debt-supplier-duedate">
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <!-- MAIN CONTAINER -->
    <b-row>
      <b-col md="12" lg="12" sm="12">
        <KTCodePreview>
          <!-- CONTENT -->
          <template v-slot:preview>
            <!-- FILTER CONTENT -->
            <b-row class="mb-5">
              <b-col md="2">
                <b-form>
                  <Autosuggest
                    :model="searchStock"
                    :suggestions="filteredOptions"
                    placeholder="cửa hàng"
                    :limit="10"
                    @select="onSelected"
                    @change="onInputChangeStore"
                    :disabled="isDisabledStore"
                  />
                </b-form>
              </b-col>
              <b-col md="2">
                <b-input-group>
                  <date-picker
                    placeholder="Từ"
                    class="form-control form-control-sm"
                    :config="dpConfigs.date"
                    v-model="apiParams.fromDate"
                  ></date-picker>
                </b-input-group>
              </b-col>
              <b-col md="2">
                <b-input-group>
                  <date-picker
                    placeholder="Đến"
                    class="form-control form-control-sm"
                    :config="dpConfigs.date"
                    v-model="apiParams.toDate"
                  ></date-picker>
                </b-input-group>
              </b-col>
              <b-col md="2">
                <b-form>
                  <Autosuggest
                    :model="searchProvider"
                    :suggestions="filteredOptionsProvider"
                    placeholder="nhà cung cấp"
                    :limit="200"
                    @select="onSelectedProvider"
                    @change="onInputChangeProvider"
                    suggestionName="suggestionName"
                  />
                </b-form>
              </b-col>
              <b-col md="2">
                <b-input
                  placeholder="Mã XNK/ PO"
                  v-model="apiParams.contractCode"
                  append-icon="search"
                  single-line
                  hide-details
                  size="sm"
                />
              </b-col>
              <b-col md="1">
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="fetchMainData()"
                  >Lọc
                </b-button>
              </b-col>
            </b-row>
            <!-- LIST DATA -->
            <b-row>
              <b-col md="12" lg="12" sm="12">
                <b-table
                  :items="mainList.dataset"
                  :fields="tableFields"
                  :busy="onLoadingList"
                  class="table-bordered table-hover"
                >
                  <template v-slot:table-busy>
                    <vcl-table :speed="5" :animate="true" :columns="10" />
                  </template>
                  <!-- Format id column -->
                  <template v-slot:cell(index)="row">
                    {{ row.index + 1 + getOffsetItem }}
                  </template>
                  <template v-slot:cell(code)="row">
                    <a
                      class="font-weight-bold"
                      v-bind:href="getLinkDoc(row.item.id, row.item.type)"
                      target="_self"
                    >
                      {{ row.item.code }}
                    </a>
                  </template>
                  <template v-slot:cell(type)="row">
                    <span v-text="getType(row.item.type)"></span>
                  </template>
                  <template v-slot:cell(purchaseOrderCode)="row">
                    <router-link
                      :to="{
                        name: 'info-purchase-orders',
                        query: { id: row.item.purchaseOrderCode },
                      }"
                      class="link"
                      >{{ row.item.purchaseOrderCode }}
                    </router-link>
                  </template>
                  <template v-slot:cell(totalAmount)="row">
                    {{ convertPrice(row.item.totalAmount) }}
                  </template>
                  <template v-slot:cell(disscountAmount)="row">
                    {{ convertPrice(row.item.disscountAmount) }}
                  </template>
                  <template v-slot:cell(payAmount)="row">
                    {{ convertPrice(row.item.payAmount) }}
                  </template>
                  <template v-slot:cell(paidAmount)="row">
                    {{ convertPrice(row.item.paidAmount) }}
                  </template>
                  <template v-slot:cell(debtAmount)="row">
                    {{ convertPrice(row.item.debtAmount) }}
                  </template>
                  <template v-slot:cell(payDate)="row">
                    <span style="display: block">{{
                      row.item.payDate && formatDate(row.item.payDate, dpConfigs.date.format)
                    }}</span>
                  </template>
                  <template
                    v-slot:cell(actions)="row"
                    v-if="isShow('TRANSACTION_INSERT')"
                  >
                    <div
                      class="d-flex justify-content-center"
                      v-if="row.item.debtAmount > 0"
                    >
                      <b-dropdown size="sm" id="dropdown-left" right>
                        <template slot="button-content">
                          <i
                            style="font-size: 1rem; padding-right: 0px"
                            class="flaticon2-settings"
                          ></i>
                        </template>
                        <div v-if="row.item.type === 1">
                          <b-dropdown-item>
                            <router-link
                              :to="{
                                name: 'transaction',
                                params: {
                                  mainModelProp: {
                                    contactType: '2',
                                    reconciliationDocType: '1',
                                    reconciliationDocId: row.item.code,
                                    type: '2',
                                    contactId: row.item.providerId,
                                    contactName: row.item.providerName,
                                    amount: row.item.debtAmount,
                                    note: getNoteForPay(row.item),
                                  },
                                  mode: 'cash',
                                  action: 'add',
                                  id: 'new',
                                },
                              }"
                            >
                              <span style="color: #3f4254; font-size: 12px">
                                <i
                                  style="font-size: 1rem"
                                  class="far fa-money-bill-alt"
                                ></i>
                                &nbsp; Tạo phiếu chi
                              </span>
                            </router-link>
                          </b-dropdown-item>
                          <b-dropdown-item>
                            <router-link
                              :to="{
                                name: 'transaction',
                                params: {
                                  mainModelProp: {
                                    contactType: '2',
                                    reconciliationDocType: '1',
                                    reconciliationDocId: row.item.code,
                                    type: '4',
                                    contactId: row.item.providerId,
                                    contactName: row.item.providerName,
                                    amount: row.item.debtAmount,
                                    note: getNoteForPay(row.item),
                                  },
                                  mode: 'bank',
                                  action: 'add',
                                  id: 'new',
                                },
                              }"
                            >
                              <span style="color: #3f4254; font-size: 12px">
                                <i
                                  style="font-size: 1rem"
                                  class="fas fa-money-check-alt"
                                ></i>
                                &nbsp; Tạo phiếu báo nợ
                              </span>
                            </router-link>
                          </b-dropdown-item>
                        </div>
                        <div v-if="row.item.type === 2">
                          <b-dropdown-item>
                            <router-link
                              :to="{
                                name: 'transaction',
                                params: {
                                  mainModelProp: {
                                    contactType: '2',
                                    reconciliationDocType: '1',
                                    reconciliationDocId: row.item.code,
                                    type: '1',
                                    contactId: row.item.providerId,
                                    contactName: row.item.providerName,
                                    amount: row.item.debtAmount,
                                    note: getNoteForPay(row.item),
                                  },
                                  mode: 'cash',
                                  action: 'add',
                                  id: 'new',
                                },
                              }"
                            >
                              <span style="color: #3f4254; font-size: 12px">
                                <i
                                  style="font-size: 1rem"
                                  class="far fa-money-bill-alt"
                                ></i>
                                &nbsp; Tạo phiếu thu
                              </span>
                            </router-link>
                          </b-dropdown-item>
                          <b-dropdown-item>
                            <router-link
                              :to="{
                                name: 'transaction',
                                params: {
                                  mainModelProp: {
                                    contactType: '2',
                                    reconciliationDocType: '1',
                                    reconciliationDocId: row.item.code,
                                    type: '3',
                                    contactId: row.item.providerId,
                                    contactName: row.item.providerName,
                                    amount: row.item.debtAmount,
                                    note: getNoteForPay(row.item),
                                  },
                                  mode: 'bank',
                                  action: 'add',
                                  id: 'new',
                                },
                              }"
                            >
                              <span style="color: #3f4254; font-size: 12px">
                                <i
                                  style="font-size: 1rem"
                                  class="fas fa-money-check-alt"
                                ></i>
                                &nbsp; Tạo phiếu báo có
                              </span>
                            </router-link>
                          </b-dropdown-item>
                        </div>
                      </b-dropdown>
                    </div>
                  </template>
                  <template slot="bottom-row">
                    <td colspan="3" class="text-right font-weight-bolder">
                      <span>Tổng</span>
                    </td>
                    <td class="text-right font-weight-bolder">
                      <span>{{ convertPrice(sumAmount('totalAmount')) }}</span>
                    </td>
                    <td class="text-right font-weight-bolder">
                      <span>{{ convertPrice(sumAmount('paidAmount')) }}</span>
                    </td>
                    <td class="text-right font-weight-bolder">
                      <span>{{ convertPrice(sumAmount('debtAmount')) }}</span>
                    </td>
                    <td></td>
                    <td v-if="isShow('TRANSACTION_INSERT')"></td>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <!-- PAGINATION -->
            <b-row>
              <b-col lg="3" md="3" sm="12">
                <p class="mt-3 text-dark" style="font-weight: 500">
                  Tổng số:
                  {{ convertPrice(mainList.total) }}
                </p>
              </b-col>
              <b-col lg="9" md="9" sm="12">
                <b-pagination-nav
                  class="custom-pagination"
                  v-show="mainList.pages >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="mainList.pages"
                  use-router
                  @change="fetchMainData()"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm m-1"
                  prev-class="page-item-prev btn btn-icon btn-sm m-1"
                  next-class="page-item-next btn btn-icon btn-sm m-1 "
                  last-class="page-item-last btn btn-icon btn-sm m-1 "
                  page-class="btn btn-icon btn-sm border-0 m-1"
                  >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
            </b-row>
          </template>
        </KTCodePreview>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import Loader from '@/view/content/Loader.vue';
import { VclTable } from 'vue-content-loading';
import ApiService from '@/core/services/api.service';
import { cmdUrl } from '@/utils/apiUrl';
import { getToastInstance } from '@/utils/toastHelper';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { checkPermissions } from '@/utils/saveDataToLocal';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { STOCK_SLIP_TYPE } from '@/utils/enum';
import { convertPrice } from '@/utils/common';
import { formatDate } from '@/utils/date';

const thStyleCommon = {
  textAlign: 'center',
  fontWeight: 600,
  color: '#181c32',
  width: '5%',
};

export default {
  data() {
    return {
      title: 'Hạn thanh toán nhà cung cấp',
      listProviders: [{ value: '', text: 'Tất cả nhà cung cấp' }],
      optionPaidStatus: [
        { value: '', text: 'Công nợ trả góp' },
        { value: 'true', text: 'Hết nợ' },
        { value: 'false', text: 'Còn nợ' },
      ],
      mainList: {
        dataset: [],
        total: 0,
        pages: 1,
      },
      apiParams: {
        page: 1,
        pageSize: 10,
        storeId: '',
        fromDate: null,
        toDate: null,
        contactId: '',
        contractCode: null,
        docId: null,
        isFullPaid: '',
      },
      linkGen: (pageNum) => {
        this.apiParams.page = pageNum;
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      onLoadingList: false,
      tableFields: [
        {
          key: 'index',
          label: 'STT',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '50px',
          },
          tdClass: 'text-center',
        },
        {
          key: 'code',
          label: 'Số phiếu',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '15%',
          },
        },
        {
          key: 'type',
          label: 'Loại phiếu',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '10%',
          },
        },
        {
          key: 'purchaseOrderCode',
          label: 'Mã PO',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '10%',
          },
        },
        {
          key: 'providerName',
          label: 'Nhà cung cấp',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: 'calc(20% - 100px)',
          },
        },
        {
          key: 'totalAmount',
          label: 'Tổng tiền',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '10%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'paidAmount',
          label: 'Đã thanh toán',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '10%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'debtAmount',
          label: 'Còn nợ',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '10%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'payDate',
          label: 'Hạn thanh toán',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '10%',
          },
          tdClass: 'text-center',
        },
        {
          key: checkPermissions('TRANSACTION_INSERT') ? 'actions' : '',
          label: '',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '50px',
          },
        },
      ],
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      filteredOptions: [],
      optionsStore: [
        {
          data: [],
        },
      ],
      searchStock: '',
      filteredOptionsProvider: [],
      optionsProvider: [
        {
          data: [],
        },
      ],
      searchProvider: '',
      providerSelected: null,
      isDisabledStore: false
    };
  },
  components: {
    KTCodePreview,
    Loader,
    VclTable,
    Autosuggest
  },
  mounted() {
    this.loadBreadcum();
    this.providerSelected = this.$route.query.providerId
      ? this.$route.query.providerId
      : null;
    this.searchProvider = this.$route.query.providerName
      ? this.$route.query.providerName
      : '';
      this.apiParams.fromDate = this.$route.query.fromDate
      ? this.$route.query.fromDate
      : '';
      this.apiParams.toDate = this.$route.query.toDate
      ? this.$route.query.toDate
      : '';
  },
  created() {
    this.loadBreadcum();
    this.fetchProviders();
    this.fetchStoreByUser();
  },
  methods: {
    convertPrice,
    ...getToastInstance(),
    formatDate,
    fetchMainData: async function () {
      let page = this.$route.query.page || 1;
      if (!page) {
        page = 1;
      }
      this.apiParams.page = page;
      this.apiParams.type = this.$route.params.mode;
      if (this.searchStock.trim() === '') {
        this.apiParams.storeId = null;
      }
      if (this.searchProvider.trim() === '') {
        this.providerSelected = null;
      }
      this.apiParams.contactId = this.providerSelected;
      this.onLoadingList = true;
      ApiService.query(cmdUrl.DebtUrl.providers, {
        params: { ...this.apiParams },
      })
        .then((response) => {
          const respData = response.data.data;
          this.mainList = respData;
          this.onLoadingList = false;
        })
        .catch((err) => {
          const message = err.response.data.message;
          this.makeToastFailure(message);
          this.onLoadingList = false;
        });
    },
    loadBreadcum: function () {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: 'Kế toán', route: 'accountant' },
        { title: 'Công nợ' },
        { title: this.title },
      ]);
    },
    fetchProviders() {
      ApiService.setHeader();
      ApiService.get(`providers/getAll`).then(({ data }) => {
        this.listProviders = data.data.providers;
        this.listProviders.map((element) => {
          let provider = {
            id: element.id,
            name: element.name,
            suggestionName: element.name,
          };
          this.optionsProvider[0].data.push(provider);
        });
        let firstProvider = {
          id: null,
          name: 'Tất cả NCC',
          suggestionName: 'Tất cả NCC',
        };
        this.optionsProvider[0].data.unshift(firstProvider);
        this.filteredOptionsProvider = [...this.optionsProvider[0].data];
      });
    },
    onSelectedProvider(option) {
      this.searchProvider = option.item.name;
      this.providerSelected = option.item.id;
    },
    onInputChangeProvider(text) {
      this.searchProvider = text;
      const filteredData = this.optionsProvider[0].data
        .filter((item) => {
          return item.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, this.limit);
      this.filteredOptionsProvider = [...filteredData];
    },
    sumAmount(key) {
      const outItems = this.mainList.dataset;
      return _.reduce(
        outItems,
        (sum, item) => {
          return sum + parseFloat(item[key]);
        },
        0
      );
    },
    getNoteForPay: function (item) {
      let note = `Thanh toán nhập hàng cho nhà cung cấp\n`;
      note += `Mã NK: ${item.code}`;
      return note;
    },
    isShow(conditions) {
      return checkPermissions(conditions);
    },
    onSelected(option) {
      this.searchStock = option.item.name;
      this.apiParams.storeId = option.item.id;
    },
    onInputChangeStore(text) {
      if (!text) {
        text = '';
      }
      this.searchStock = text;

      const filteredData = this.optionsStore[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, 10);

      this.filteredOptions = [...filteredData];
    },
    fetchStoreByUser() {
      ApiService.setHeader();
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          const stores = data.data.stores;
          if (stores.length === 1) {
            this.searchStock = stores[0].name;
            this.apiParams.storeId = stores[0].id;
            this.isDisabledStore = true;
          } else {
            this.optionsStore[0].data = [];
            const data = {
              id: '',
              name: 'Tất cả cửa hàng',
              shortName: 'Tat ca cua hang',
            };
            this.optionsStore[0].data.push(data);
            stores.map((element) => {
              this.optionsStore[0].data.push(element);
            });
            this.filteredOptions = [...this.optionsStore[0].data];
            this.searchStock = data.name;
            this.apiParams.storeId = data.id;
          }

          this.fetchMainData();
        }
      });
    },
    getType: function (type) {
      switch (type) {
        case STOCK_SLIP_TYPE.IMPORT_PROVIDER:
          return 'Nhập kho';
        case STOCK_SLIP_TYPE.EXPORT_PROVIDER:
          return 'Xuất trả';
        default:
          return 'Không rõ';
      }
    },
    getLinkDoc(id, type) {
      if (type === STOCK_SLIP_TYPE.IMPORT_PROVIDER) {
        return `#/stocks/update-stock?id=${id}`;
      } else if (type === STOCK_SLIP_TYPE.EXPORT_PROVIDER) {
        return `#/export-stocks/update-export-stock?id=${id}`;
      }
    },
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
    getOffsetItem() {
      return (this.apiParams.page - 1) * this.apiParams.pageSize;
    },
  },
  beforeRouteUpdate: function (to, from, next) {
    this.mode = to.params.mode;
    next();
  },
  watch: {
    mode: function (val) {
      this.mode = val;
      this.fetchMainData();
      this.loadBreadcum();
    },
  },
};
</script>

<style lang="scss">
.debt-supplier-duedate {
  .icon:hover {
    background-color: #90c6fc;
  }
  .sttClass {
    width: 20px;
  }
  .link {
    color: unset;
  }
  .link:hover {
    text-decoration: underline !important;
    cursor: pointer !important;
  }
  .page-item.disabled {
    cursor: not-allowed;
    &.bv-d-xs-down-none {
      .page-link {
        margin: 0.35rem 0.25rem;
        text-align: center;
      }
    }
  }
}
</style>
